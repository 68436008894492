<template>
  <div>
    <Header :title="title"/>
    <div class="success-box">
      <div>
        <i class="el-icon-success theme-color"></i>
      </div>
      <div class="top-title">{{title}}申请成功</div>
      <div class="top-subtitle">{{tips}}</div>
      <div><button class="wid-btn" style="width:80%" @click="handerConfirm">完成</button></div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return{
      type:this.$route.query.type,
      title:'提现',
      tips:"一般24小时到账，最迟不超过3个工作日，具体到账时间与银行操作有关"
    }
  },
  created(){
    // 提现
    if(this.type=='withdraws'){
      this.title = '提现'
      this.tips = "一般24小时到账，最迟不超过3个工作日，具体到账时间与银行操作有关"

    }else if(this.type=='transfer'){
      this.title = '转账'
      this.tips = "您的转账请求已发出，系统会在24小时内处理。客服可能需要联系您本人进行确认，请您耐心等待。"
    }
  },
  methods:{
    handerConfirm(){
      this.$router.push({path:"my"})
    }
  }
}
</script>
<style lang="scss" scoped>
.success-box{
  text-align: center;
  margin-top:8rem;
  .el-icon-success{
    font-size: 4rem;
    margin-bottom:1rem
  }
  .top-title{
    font-size: 1rem;
    line-height: 2;
    margin-bottom: 1.5rem;
  }
  .top-subtitle{
    width: 70%;
    margin: 0px auto 50px;
    font-size: .9rem;
    color: #464646;
  }
}
</style>