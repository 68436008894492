var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", { attrs: { title: _vm.title } }),
      _c("div", { staticClass: "success-box" }, [
        _vm._m(0),
        _c("div", { staticClass: "top-title" }, [
          _vm._v(_vm._s(_vm.title) + "申请成功"),
        ]),
        _c("div", { staticClass: "top-subtitle" }, [_vm._v(_vm._s(_vm.tips))]),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "wid-btn",
              staticStyle: { width: "80%" },
              on: { click: _vm.handerConfirm },
            },
            [_vm._v("完成")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { staticClass: "el-icon-success theme-color" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }